@font-face {
    font-family: 'Uniform';
    src: url('./assets/fonts/Uniform.eot');
    src: url('./assets/fonts/Uniform.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Uniform.woff') format('woff'),
        url('./assets/fonts/Uniform.ttf') format('truetype'),
        url('./assets/fonts/Uniform.svg#Uniform') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uniform';
    src: url('./assets/fonts/Uniform-Medium.eot');
    src: url('./assets/fonts/Uniform-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Uniform-Medium.woff') format('woff'),
        url('./assets/fonts/Uniform-Medium.ttf') format('truetype'),
        url('./assets/fonts/Uniform-Medium.svg#Uniform') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uniform';
    src: url('./assets/fonts/Uniform-Bold.eot');
    src: url('./assets/fonts/Uniform-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Uniform-Bold.woff') format('woff'),
        url('./assets/fonts/Uniform-Bold.ttf') format('truetype'),
        url('./assets/fonts/Uniform-Bold.svg#Uniform') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uniform';
    src: url('./assets/fonts/Uniform-Ultra.eot');
    src: url('./assets/fonts/Uniform-Ultra.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Uniform-Ultra.woff') format('woff'),
        url('./assets/fonts/Uniform-Ultra.ttf') format('truetype'),
        url('./assets/fonts/Uniform-Ultra.svg#Uniform') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}