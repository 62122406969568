/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
    width: 304px;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--bottom-left {
    left: 290px;
    bottom: 85px;
}

/** Classes for the displayed toast **/
.Toastify__toast {
    animation: none !important;
}
.Toastify__toast--rtl {}
.Toastify__toast--default {
    background: none;
    padding: 0;
    border-radius: 5px;
}
.Toastify__toast-body {
    margin: 0;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    display: none;;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
}