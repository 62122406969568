@import "Toastify.css";

html,
body,
#root {
	width: 100%;
  height: 100%;
  user-select: none;
  overflow-x: hidden;
  overscroll-behavior: none;
}

html:-webkit-full-screen {
  width: 100%;
  height: 100%;
}